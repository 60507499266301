<template>
    <div class="contentwrapper">
        <h1 class="heading">Take a picture with your mobile phone.</h1>
    <div class="innerContentWrapper">
      <div class="textwrapper">
        <p class="paragraph">Take a photo of your object with the gummy mobile app. Afterwords you have to describe it.</p>
        <p class="paragraph">These informations will be secured in the blockchain.</p>
        <textarea id="textareaTitle" v-model="title" placeholder="Title of your WHATEVER" maxlength="200" class="textarea w-input"></textarea>
        <textarea id="textarea" v-model="text" placeholder="Description of your WHATEVER" maxlength="5000" class="textarea w-input"></textarea>
        <div class="navigationRow">
          <div class="button secondary back" variant="secondary" @click="onClickBack">Back</div>
          <div :class="['button', isReadyForMint() ? '' : 'disabled']" variant="primary" @click="onClickNext">Next</div>
        </div>
      </div>
      <div class="visualswrapper">
        <img class="objectPic photo" v-if="imagePath" :src="getImagePath()" />
        <div class="btn delete" @click="onDeleteImage">
          <div class="btncontentwrapper">
            <h3 class="btnfont">Retry</h3>
          </div>
        </div>
        <img class="objectPic takePhoto" v-if="!imagePath" src="@/assets/takePhoto3.png" loading="lazy" sizes="28vw" alt="">
      </div>
    </div>
  </div>

</template>

<script>

  import { EventBus } from '@/event-bus';

  export default {
    components: {
    },
    props: {
      pImagePath: String,
      pDescription: String,
      pTitle: String,
    },
    data() {
      return {
        imagePath: null,
        text: null,
        title: null
      }
    },
    watch: {
      text() {
        this.$emit('descriptionChange', this.text)
      },
      title() {
        this.$emit('titleChange', this.title)
      }
    },
    methods: {
      onClickBack() {
        this.$emit('back');
      },
      onClickNext() {
        if(!this.isReadyForMint()) {
          this.$toast.open({
            message: this.getWarnMessage(),
            type: 'warning',
            position: 'bottom',
            duration: 6000
          });
          return;
        }
        this.$emit('next');
      },
      onDeleteImage() {
        this.$emit('deleteImage');
      },
      getWarnMessage() {
        if((!this.imagePath || this.imagePath.length < 5) && (!this.text || this.text.trim().length < 1)) {
          return 'Take a picture with your mobile phone and describe your WHATEVER.'
        }
        
        if(!this.imagePath || this.imagePath.length < 5) {
          return 'Take a picture with your mobile phone.'
        }

        return 'Describe your WHATEVER.'
      },
      getImagePath() {
        return `https://api.gummy.link/api/v1/image?path=${this.imagePath}&gummy-api-token=OWSkiVErkyARYONYScHePLEnt`
      },
      isReadyForMint() {
        if(!this.imagePath || this.imagePath.length < 5) {
          return false;
        }

        if(!this.text || this.text.trim().length < 1) {
          return false;
        }

        if(!this.title || this.title.trim().length < 1) {
          return false;
        }

        return true;
      }
    },
    mounted() {
      this.imagePath = this.pImagePath;
      this.text = this.pDescription;
      this.title = this.pTitle;
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        console.log(pairingDoc.data.image)

        if(this.imagePath !== pairingDoc.data.image && pairingDoc.data.image && pairingDoc.data.image.length > 0) {
          this.$emit('newImage');
        }

        this.imagePath = pairingDoc.data.image
      })
    }
  }
</script>
