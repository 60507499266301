<template>
  <div class="contentwrapper" v-if="renderComponent">

    <template v-if="isRunning()">
      <h1 class="heading">Minting in progress</h1>
      <div class="innerContentWrapper">
        <div class="textwrapper">
          <div class="verticalwrapper">
            <div class="row substeprow" v-if="mintingProgress.minting_exec_step > 0">
              <div class="substep">1</div>
              <div class="substepcontent">
                <h4 v-if="!mintingProgress.ipfsHash" class="substepheading">Pining image on IPFS ...</h4>
                <template v-else>
                  <h4 class="substepheading">Image pinned on IPFS.</h4>
                  <p class="substepdescription">Your image was successful uploaded and pinned on IPFS. <a :href="mintingProgress.ipfsUrl" target="_blank">View it here.</a></p>
                </template>
              </div>
            </div>

            <div class="row substeprow" v-if="mintingProgress.minting_exec_step == 2">
              <div class="substep">2</div>
              <div class="substepcontent">
                <h4 v-if="!mintingProgress.txHash" class="substepheading">Approve minting transaction</h4>
                <p class="substepdescription">Please approve the transaction in your Metamask wallet. Pay attention to the fees.</p>
              </div>
            </div>

            <template v-if="mintingProgress.minting_exec_step == 3">
              <div class="row substeprow">
                <div class="substep">2</div>
                <div class="substepcontent">
                  <h4 class="substepheading">Minting transaction has been approved by you.</h4>
                </div>
              </div>

              <div class="row substeprow">
                <div class="substep">3</div>
                <div class="substepcontent">
                  <h4 class="substepheading">Waiting for minting execution</h4>
                  <p class="substepdescription">The transaction ({{ getTxShortHash(mintingProgress.txHash) }}) is currently being processed by the network. <a :href="mintingProgress.txMonitorUrl" target="_blank">View it here.</a></p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isError()">
      <h1 class="heading">Minting failed</h1>
      <div class="innerContentWrapper">
        <div v-if="mintingProgress.reason === 'deniedTx'" class="textwrapper fullWidth">
          <p class="paragraph">You denied the transaction.</p>
          <div class="navigationRow">
            <div class="button" variant="primary" @click="onClickRetry">Retry</div>
            <div class="button secondary" style="white-space: nowrap;" variant="primary" @click="onClickRestart">Restart process</div>
            <div class="flex-grow"></div>
          </div>
        </div>
        <div v-if="mintingProgress.reason === 'txFailed'" class="textwrapper fullWidth">
          <p class="paragraph">The transaction has been failed. Please check the transaction on etherscan: <a :href="mintingProgress.txMonitorUrl" target="_blank">{{ getTxShortHash(mintingProgress.txHash) }}</a></p>
          <div class="navigationRow">
            <div class="button" style="white-space: nowrap;" variant="primary" @click="onClickRestart">Restart process</div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isSuccess()">
      <h1 class="heading">Minting is done</h1>
      <div class="innerContentWrapper">
        <div class="textwrapper">
          <p class="paragraph">Yes, you have successfully minted your own gummy. Congratulations.</p>
          <p class="paragraph">Now you can sell it on <a href="https://opensea.io">opensea.io</a>.</p>
        </div>
        <div class="navigationRow">
          <div class="button" variant="primary" @click="onClickRestart">Mint one more!</div> 
        </div>
      </div>
    </template>

  </div>
</template>

<script>

  import { EventBus } from '@/event-bus';

  export default {
    components: {
    },
    props: {
      pairingDoc: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        mintingProgress: {},
        renderComponent: true
      }
    },
    methods: {
      isDone() {
        return this.isError() || this.isSuccess();
      },
      isRunning() {
        return !this.isDone();
      },
      isError() {
        return this.mintingProgress.result && this.mintingProgress.result === 'error';
      },
      isSuccess() {
        return this.mintingProgress.result && this.mintingProgress.result === 'success';
      },
      onClickRetry() {
        this.$emit('minting');
      },
      onClickRestart() {
        this.$emit('restart');
      },
      forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      getTxShortHash(hash) {
        if(!hash) {
          return "";
        }
        return `${hash.substring(0,5)}...${hash.substring(hash.length - 3)}`;
      }
    },
    mounted() {
      EventBus.$on('mintingUpdate', (data) => {
        console.log(data)
        this.mintingProgress = data
        this.forceRerender()
      })
    }
  }
</script>
