<template>
  <div class="contentwrapper">
    <h1 class="heading">Connect your mobile phone.</h1>
    <div class="innerContentWrapper">
      <div class="textwrapper">
        <div class="verticalwrapper">
          <div class="row substeprow">
            <div class="substep">1</div>
            <div class="substepcontent">
              <h4 class="substepheading">Download gummy.link app <br>from your App Store. Note: Unfortunately, the app is currently only available in the PlayStore for Android. We are currently clarifying with Apple how we can comply with the guidelines.</h4>
            </div>
          </div>
          <div class="row substeprow">
            <div class="substep">2</div>
            <div class="substepcontent">
              <h4 class="substepheading mb-2-5">Scan this QR-Code in the App:</h4>
              <div class="qrcodewrapper">
                <qrcode-vue :value="getPairingRefCode()" background="#fff" foreground="#000" margin=10></qrcode-vue>
              </div>
            </div>
          </div>
        </div>
        <div class="navigationRow">
          <div></div>
          <div v-if="paired" class="button" variant="primary" @click="onClickNext">Next</div>
        </div>
      </div>
      <div class="visualswrapper">
        <img v-if="!paired" src="@/assets/scanQR.png" loading="lazy" alt="" class="objectPic connectMobile">
      </div>
    </div>
    <p class="paragraph secondary last">The next step will appear <span class="text-span">automatically</span> once you have connected your phone.</p>
  </div>
</template>

<script>

  import QrcodeVue from 'qrcode.vue'
  import { EventBus } from '@/event-bus';

  export default {
    components: {
      QrcodeVue
    },
    props: {
      pairingRef: {
        type: String,
        required: true
      },
      pairingState: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        paired: this.pairingState
      }
    },
    methods: {
      onClickBack() {
        this.$emit('back');
      },
      onClickNext() {
        this.$emit('next');
      },
      getPairingRefCode() {
        return `annftapp:pairingRef:${this.pairingRef}`
      }
    },
    mounted() {
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        this.paired = pairingDoc.data.mappConnected
        if(this.paired) {
          this.$emit('mappConnected');
        }
      })
    }
  }
</script>
