<template>
  <div class="contentwrapper">
    <h1 class="heading">Stick gummy on your object and scan it.</h1>
    <div class="innerContentWrapper">
      <div class="textwrapper">
        <p class="paragraph">
          When the gummy is on your object
          you can scan it by using the gummy app
          on your phone.
        </p>
        <p class="paragraph">
          If you dont have a gummy, you can <a href="http://gummy.link/">order it here</a>.
        </p>
        <div class="navigationRow">
          <div class="button secondary back" variant="secondary" @click="onClickBack">Back</div>
        </div>
      </div>
      <div class="visualswrapper"><img class="objectPic scanIt" src="@/assets/3.png" loading="lazy" alt=""></div>
    </div>
    <!-- button class="float-right" variant="primary" @click="onClickNext">Next</button -->
    <p class="paragraph secondary last">
          The next step will appear <b>automatically</b> once you have scanned your gummy.
    </p>
  </div>
</template>

<script>

  import { EventBus } from '@/event-bus';

  export default {
    components: {
    },
    data() {
      return {
        code: null,
        renderComponent: true,
        codeAlreadyMinted: null,
        toastPrell: false
      }
    },
    methods: {
      onClickBack() {
        this.$emit('back');
      },
      onClickNext() {
        this.$emit('next');
      },
      forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      }
    },
    mounted() {
      EventBus.$on('updatePairingDoc', (pairingDoc) => {
        console.log('updatePairingDoc event received!')
        this.code = pairingDoc.data.code
        if(this.code && this.code.length > 5) {
          if(pairingDoc.data.codeAlreadyMinted) {
            this.codeAlreadyMinted = pairingDoc.data.codeAlreadyMinted;
            this.forceRerender();
            this.$emit('gummyAlreadyMinted');

            if(!this.toastPrell) {
              this.toastPrell = true
              this.$toast.open({
                message: 'Oh no! The gummy is already minted. Please use another one.',
                type: 'error',
                position: 'bottom',
                duration: 6000
              });
              setTimeout(() => this.toastPrell = false, 2000)
            }
          } else {
            this.codeAlreadyMinted = null;
            this.$emit('gummyScanned');
          }
        }
      })
    },
    unmounted() {
      if(this.timeoutAlredayMintedWarn) {
        clearTimeout(this.timeoutAlredayMintedWarn);
      }
    }
  }
</script>
