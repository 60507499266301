<template>
  <div class="contentwrapper transparent">
    <div class="textwrapper fullWidth">
      <h1 class="heading">Minting</h1>
      <p class="paragraph">Yeah! You can start to mint your own NFT!<br>Please check everything again. If you think everything fits, click on Minting.</p>
      <div class="mintingWrapper">
        <div class="centeredContent">
          <img class="chainLogo" src="@/assets/ethereum-logo-landscape-purple.png">
        </div>
        <div class="row">
          <div class="col right">
            <img class="objectPic photo" :src="getImagePath()" />
          </div>
          <div class="col left">
            <div>
              <div class="label">Network</div>
              <div class="value">{{ wallet.type }}</div>
            </div>
            <div>
              <div class="label">Address</div>
              <div class="value">{{ wallet.metaMaskAddress.substring(0,15)}}<br>{{ wallet.metaMaskAddress.substring(16,30)}}</div>
            </div>
            <div>
              <div class="label">NFT-Code</div>
              <div class="value">0x{{ pairingDoc.data.code }}</div>
            </div>
            <p class="paragraph">{{ title }}</p>
            <p class="paragraph">{{ description }}</p>
            <div style="margin-top: 3rem;">
              <div class="button" variant="primary" @click="onClickMinting">Minting</div>
            </div>
          </div>
            <div class="navigationRow">
              <div class="button secondary back" variant="secondary" @click="onClickBack">Back</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
    },
    props: {
      wallet: {
        type: Object,
        required: true
      },
      pairingDoc: {
        type: Object,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },
    data() {
      return {
      }
    },
    methods: {
      onClickBack() {
        this.$emit('back');
      },
      onClickMinting() {
        this.$emit('minting');
      },
      getImagePath() {
        return `https://api.gummy.link/api/v1/image?path=${this.pairingDoc.data.image}&gummy-api-token=OWSkiVErkyARYONYScHePLEnt`
      }
    }
  }
</script>
