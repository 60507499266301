<template>
  <div class="body">
    <!--<div style="position: absolute;"><div class="btn" variant="primary" @click="onClickNext">Next</div></div>-->
    <div class="topsection">
    <div class="addresswrapper" v-bind:class="{ show: wallet && wallet.type !== 'NO_LOGIN' && wallet.type !== 'NO_INSTALL_METAMASK' }">
      <div v-if="wallet && wallet.type !== 'NO_LOGIN'" class="simpleRow">
        <div class="cryptoadress">{{ wallet.metaMaskAddress.substring(0,4) }}...{{wallet.metaMaskAddress.substring(wallet.metaMaskAddress.length - 4)}}</div>
        <div class="cryptonetwork">{{ wallet.type }}</div>
      </div>
    </div>
      <div class="logowrapper"><img class="image logo" src="@/assets/gummy.svg" loading="lazy" alt=""></div>
      <div class="process">
        <div :class="['step', getCssForStep(1)]">
          <div :class="['number', getCssForStep(1)]">1</div>
          <div class="stepnamewrapper">
            <div :class="['stepname', getCssForStep(1)]">Connect<br>wallet</div>
          </div>
        </div>
        <div class="connectionwrapper">
          <div class="connectioninactive"></div>
          <div :class="['connectionactive', getCssForConnection(1)]"></div>
        </div>
        <div :class="['step', getCssForStep(2)]">
          <div :class="['number', getCssForStep(2)]">2</div>
          <div class="stepnamewrapper">
            <div :class="['stepname', getCssForStep(2)]">Pairing<br>App</div>
          </div>
        </div>
        <div class="connectionwrapper">
          <div class="connectioninactive"></div>
          <div :class="['connectionactive', getCssForConnection(2)]"></div>
        </div>
        <div :class="['step', getCssForStep(3)]">
          <div :class="['number', getCssForStep(3)]">3</div>
          <div class="stepnamewrapper">
            <div :class="['stepname', getCssForStep(3)]">Scan <br>gummy</div>
          </div>
        </div>
        <div class="connectionwrapper">
          <div class="connectioninactive"></div>
          <div :class="['connectionactive', getCssForConnection(3)]"></div>
        </div>
        <div :class="['step', getCssForStep(4)]">
          <div :class="['number', getCssForStep(4)]">4</div>
          <div class="stepnamewrapper">
            <div :class="['stepname', getCssForStep(4)]">Take<br>photo</div>
          </div>
        </div>
        <div class="connectionwrapper">
          <div class="connectioninactive"></div>
          <div :class="['connectionactive', getCssForConnection(4)]"></div>
        </div>
        <div :class="['step', getCssForStep(5)]">
          <div :class="['number', getCssForStep(5)]">5</div>
          <div class="stepnamewrapper">
            <div :class="['stepname', getCssForStep(5)]">Minting</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="contentsection">
      <div v-if="current_step==1" class="stepWrapper">
        <connect-wallet v-on:next="onClickNext" v-on:walletChange="onWalletChange" :wallet="wallet"></connect-wallet>
      </div>

      <div v-if="current_step==2" class="stepWrapper">
        <pairing-app v-on:back="onClickBack" v-on:next="onClickNext" :pairingRef="pairingRef" :pairingState="mappConnected" v-on:mappConnected="onMappConnected"></pairing-app>
      </div>

      <div v-if="current_step==3" class="stepWrapper">
        <scan-code v-on:back="onClickBack" v-on:next="onClickNext" v-on:gummyScanned="onGummyScanned" v-on:gummyAlreadyMinted="onGummyAlreadyMinted"></scan-code>
      </div>

      <div v-if="current_step==4" class="stepWrapper">
        <take-picture-and-meta v-on:back="onClickBack" v-on:next="onClickNext" v-on:descriptionChange="onDescriptionChange" v-on:titleChange="onTitleChange" v-on:deleteImage="onDeleteImage" v-on:newImage="requestMobileScreen('waitForMintStart')" :pImagePath="pairingDoc.data.image" :pDescription="description" :pTitle="title"></take-picture-and-meta>
      </div>

      <div v-if="current_step==5" class="stepWrapper">
        <start-minting  v-on:back="onClickBack" v-on:minting="onClickStartMinting" :wallet="wallet" :pairingDoc="pairingDoc" :description="description" :title="title"></start-minting>
      </div>

      <div v-if="current_step==6" class="stepWrapper">
        <minting-process v-on:done="onClickDone" v-on:minting="onClickStartMinting" v-on:restart="onClickRestart" :pairingDoc="pairingDoc" :description="description" :title="title"></minting-process>
      </div>

    </div>
  </div>
</template>

<script>
  //import VueMetamask from 'vue-metamask';
  import ConnectWallet from './components/steps/ConnectWallet.vue';
  import PairingApp from './components/steps/PairingApp.vue';
  import ScanCode from './components/steps/ScanCode.vue';
  import TakePictureAndMeta from './components/steps/TakePictureAndMeta.vue';
  import StartMinting from './components/steps/StartMinting.vue';
  import MintingProcess from './components/steps/MintingProcess.vue';
  import { EventBus } from '@/event-bus';
  import { waitTransaction, isSuccessfulTransaction } from '@/utils/tx-state-change-listener.js'
  import contract from '@/contracts/GummyNftSC.json'

  export default {
    components: {
      ConnectWallet,
      PairingApp,
      ScanCode,
      TakePictureAndMeta,
      StartMinting,
      MintingProcess
    },
    data() {
      return {
        name: 'BootstrapVue',
        show: true,
        msg: "This is demo net work",
        current_step: 1,
        wsconnection: null,
        pairingRef: null,
        mappConnected: false,
        pairingDoc: null,
        description: "",
        title: "",
        wallet: null,
        network: null,
        pleaseConnectWallet: false,
        minting: "preparation",
        ipfsHash: null,
        mintRef: null,
        txHash: null,
        txDone: null,
        txState: null,
        isWalletConnected: false,
        configKonva: {
          width: 10,
          height: 10
        },
        configCircleConnected: {
          x: 5,
          y: 5,
          radius: 2.5,
          fill: "green",
        },
        configCircleDisconnected: {
          x: 5,
          y: 5,
          radius: 2.5,
          fill: "red",
        }
      }
    },
    watch: {
      show(newVal) {
        console.log('Alert is now ' + (newVal ? 'visible' : 'hidden'))
      },
      pairingDoc: {
        handler() {
          console.log("emit updatePairingDoc event")
          EventBus.$emit('updatePairingDoc', this.pairingDoc)
        },
        deep: true
      }
    },
    computed: {
      progress: function() {
        return Math.round(100 / (this.minting === 'preparation' ? 5 : 4)) * this.current_step;
      }
    },
    methods: {
      getCssForStep(step) {
        if(this.current_step == 6 && step == 5) {
          step = 6
        } 

        if(this.current_step == step) {
          return 'current'
        }

        if(this.current_step > step) {
          return 'over'
        }

        return ''
      },
      getCssForConnection(step) {
        if(this.current_step == 6 && step == 5) {
          step = 6
        } 

        if(this.current_step > step) {
          return 'active'
        }
        return ''
      },
      toggle() {
        this.show = !this.show
      },
      dismissed() {
        console.log('Alert dismissed')
      },
      requestMobileScreen(requestedScreen) {

        var screen = requestedScreen
        if(!screen) {
          if(this.current_step == 2) {
            screen = 'scanQR'
          } else if(this.current_step == 3) {
            screen = 'scanGummy'
          } else if(this.current_step == 4) {
            screen = 'takePicture'
          }
        }

        console.log('requestMobileScreen', screen)

        this.$http({
          method: 'POST',
          url: `https://api.gummy.link/api/v1/pairing/${this.pairingDoc.id}/go-to-screen`,
          data: JSON.stringify({
            screen: screen
          }),
          headers: {
            "content-type": "application/json",
            "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
          }
        })
        .then(() => {
          return true
        }).catch(error => {
          console.log('something went wrong!', error)
        })
      },
      onComplete(data){
        console.log('data:', data);
      },
      onClickNext() {
        console.log("onClickNext")
        this.current_step++;
        this.requestMobileScreen();
      },
      onClickBack() {
        this.current_step--;
        this.requestMobileScreen();
      },
      onClickDone() {
        // TODO restart proccess!!!!!
        this.current_step = 1;
      },
      onClickRestart() {
        // TODO restart proccess!!!!!
        this.current_step = 1;
      },
      onClickFirst() {
        this.current_step = 1;
      },
      onDeleteImage() {
        this.$http({
          method: 'DELETE',
          url: `https://api.gummy.link/api/v1/nft-preparation/${this.pairingDoc.id}/image`,
          data: JSON.stringify({}),
          headers: {
            "content-type": "application/json",
            "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
          }
        })
        .then(() => {
          this.requestMobileScreen('takePicture')
        }).catch(error => {
          console.log('something went wrong!', error)
        })

      },
      onWalletChange(value) {
        this.wallet = value.wallet
        this.network = value.network
        this.isWalletConnected = this.network && this.wallet.metaMaskAddress && this.wallet.metaMaskAddress.length > 10 ? true : false;
        console.log(this.wallet)
      },
      onMappConnected() {
        this.onClickNext();
      },
      onGummyScanned() {
        this.onClickNext();
      },
      onGummyAlreadyMinted() {
        this.requestMobileScreen('scanGummy')
      },
      onDescriptionChange(value) {
        this.description = value
      },
      onTitleChange(value) {
        this.title = value
      },
      onClickStartMinting() {

        if(!this.network) {
          return;
        }

        this.current_step = 6
        this.requestMobileScreen('mintInProgress')

        Promise.resolve({
          minting_exec_step: 1,
          web3: this.wallet.web3
        }).then(ctx => {
          EventBus.$emit('mintingUpdate', ctx);
          return new Promise((resolve, reject) => {
            var data = {
              "code": this.pairingDoc.data.code,
              "title": this.title,
              "description": this.description,
              "image": this.pairingDoc.data.image,
              "network": this.network.key
            };

            console.log("data to post", data)

            this.$http({
              method: 'POST',
              url: 'https://api.gummy.link/api/v1/pinFileToIPFS',
              data: JSON.stringify(data),
              headers: {
                "content-type": "application/json",
                "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
              }
            })
            .then(resp => {
              console.log(resp.status)
              ctx.ipfsHash = resp.data.ipfsHash
              ctx.mintRef = resp.data.mintRef
              ctx.ipfsUrl = `https://gateway.gummy.link/ipfs/${ctx.ipfsHash}`
              ctx.minting_exec_step = 2
              EventBus.$emit('mintingUpdate', ctx)
              resolve(ctx)
            })
            .catch(error => reject(error));
          });
        }).then(async ctx => {
            ctx.txCount = await ctx.web3.eth.getTransactionCount(this.wallet.metaMaskAddress)
            return ctx;
        }).then(ctx => {
          return new Promise((resolve, reject) => {
            const gummyNftSc = new ctx.web3.eth.Contract(contract.abi, this.network.contract)
            const salting = (id) => {
              const check = 0xfcd01b9;
              var salt = id & check;
              salt >>>= 6;
              salt += 0x2313d;
              return salt;
            }

            const intValue = parseInt(this.pairingDoc.data.code, 16)
            console.log(this.pairingDoc.data.code, intValue, salting(intValue))

            const transaction = {
              'from': this.wallet.metaMaskAddress,
              'to': this.network.contract,
              'nonce': ctx.txCount,
              'data': gummyNftSc.methods.mintToken(this.wallet.metaMaskAddress, intValue, salting(intValue)).encodeABI()
            };

            ctx.web3.eth.sendTransaction(transaction, (error, hash) => {
              if(error) {
                ctx.result = 'error'
                if(error.code == 4001) {
                  ctx.reason = 'deniedTx'
                }

                return reject(ctx)
              }

              ctx.txHash = hash
              ctx.txMonitorUrl = `${this.network.monitorUrl}${ctx.txHash}`

              this.$http({
                method: 'POST',
                url: 'https://api.gummy.link/api/v1/mintNFT/' + ctx.mintRef,
                data: JSON.stringify({tx: hash}),
                headers: {
                  "content-type": "application/json",
                  "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
                }
              });
            
              ctx.minting_exec_step = 3
              EventBus.$emit('mintingUpdate', ctx)
              resolve(ctx)
            })
          })
        }).then(async ctx => {
          ctx.receipt = await waitTransaction(ctx.web3, ctx.txHash);
          console.log("after waitTransaction", ctx.receipt);
          if(isSuccessfulTransaction(ctx.receipt)) {
            ctx.result = 'success'
          } else {
            ctx.result = 'error'
            ctx.reason = 'txFailed'
          }
          ctx.minting_exec_step = 4;
          EventBus.$emit('mintingUpdate', ctx);
          return ctx;
        }).catch(ctx => {
          console.log("Can't mint!");
          EventBus.$emit('mintingUpdate', ctx);
        })
      }
    },
    created() {
      this.$http({
        method: 'POST',
        url: 'https://api.gummy.link/api/v1/pairing/new-challenge',
        json: {},
        headers: {
          "content-type": "application/json",
          "gummy-api-token": "OWSkiVErkyARYONYScHePLEnt"
        }
      })
      .then(resp => {
        console.log(resp.data)
        this.pairingRef = resp.data.pairingRef
        this.wsconnection = new WebSocket(`wss://api.gummy.link/api/v1/pairing/${this.pairingRef}/dapp?gummy-api-token=OWSkiVErkyARYONYScHePLEnt`)
        this.wsconnection.onmessage = (event) => {
          this.pairingDoc = JSON.parse(event.data)
          this.mappConnected = this.pairingDoc.data.mappConnected
          console.log(JSON.stringify(this.pairingDoc));
        }
        this.wsconnection.onopen = (event) => {
          console.log(event)
        }
      })
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        // always executed
      })
    }
  }
</script>